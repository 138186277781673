<template>
     <div class="container">
        <div class="card mt-2">
            <h5 class="text-primary mb-0 font-poppins-semibold">
                Please read the following instructions carefully before proceeding.
            </h5>
            <div class="ml-5">
            <ul>
                <li class="mt-2">Do not refresh the page during the session, as it may lead to loss of progress.</li>
                <li class="mt-2">Close all unnecessary applications and browser tabs to avoid distractions.</li>
                <li class="mt-2">Click "Start Interview" to begin.</li>
                <li class="mt-2">Use the "Next" button to navigate next questions.</li>
                <li class="mt-2">No option to update or edit answered questions once submitted.</li>
                <li class="mt-2">All questions should be answered before submitting the interview.</li>
                <li class="mt-2 mb-2">Click "Submit Interview" only when you have completed all questions.</li>

            </ul>
                </div>
            <h6 class="text-secondary mb-2 font-poppins-semibold">Good luck! </h6>
        </div>
        <div class="card mt-2">
                <btn text="Start Interview" icon="fa fa-pencil"
                     @click="startInterview()"></btn>
            </div>
     </div>
</template>

<script>
// import axios from 'secure-axios';
// import urls from '../../../data/urls';

import store from '@/store';

export default {
    name: 'AttendInterviewHome',
    methods: {
        async startInterview () {
            this.$router.push('/mcq-interview/interview_page/');
            // this.loading = true;
            // const response = await axios.form(urls.mcq_interview.interview.attend, { id: this.id });
            // const result = response.data;
            // if (result.success) {
            //     // this.$notify(' Questions Generated Successfully', 'Success', { type: 'success' });
            //     this.loading = false;
            // } else {
            //     const errors = response.data.errors;
            //     for (const key in errors) {
            //         if (Object.prototype.hasOwnProperty.call(errors, key)) {
            //             let errorMessage = '';
            //             if (key === '__all__') {
            //                 errorMessage = `${errors[key]}`;
            //             } else {
            //                 errorMessage = `${key}:  ${errors[key]}`;
            //             }
            //             this.$notify(errorMessage, 'Warning', { type: 'warning' });
            //         }
            //     }
            //     this.loading = false;
            // }
        }
    },
    mounted () {
        const interview = store.getters.interview;
        const candidate = store.getters.candidate;
        // const candidate = this.$store.state.candidate;
        // const interview = this.$store.state.interview;
        console.log('---candidate7777-----', candidate);
        console.log('---interview7777777-----', interview);
    }
};
</script>

<style scoped>

</style>
